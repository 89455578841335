#tokengenerator {
  position: relative;
  inset: 0 0 0 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  transform-style: preserve-3d;
  min-height: max(100%, 950px);
  isolation: isolate;
  //overflow: hidden;
  .zeniq-logo {
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 80px;
    width: 80px;
  }
}

$height-no-error: 660px;
//$height-no-error: 700px;

.form-error {
  max-height: 730px;
  min-height: 730px;
}
.form-no-error {
  max-height: $height-no-error;
  min-height: $height-no-error;
}

.form {
  * {
    transition: 0.4s ease-in-out;
  }
  transition: 0.4s ease-in-out;
  padding: 1rem;
  background: #1d1d1e;
  width: 500px;
  overflow: hidden;
  border-radius: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .header {
    user-select: none;
    font-size: 28px;
    padding: 10px 0 0 10px;
    font-weight: 700;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: flex-start;
    isolation: isolate;
    .input {
      height: 90px;
      position: relative;
      inset: 0 0 0 0;
      background: #21242a;
      border-radius: 20px;
      input {
        cursor: pointer;
        width: 100%;
        height: 90px;
        position: absolute;
        inset: 0 0 0 0;
        background: transparent;
        padding-left: 20px;
        padding-top: 20px;
        font-size: 24px;
        border-radius: 20px;
        border: 1px solid rgb(44, 47, 54);
        &:focus-visible {
          outline: none;
          border: 2px solid rgb(66, 66, 66);
        }
      }
      .label {
        user-select: none;
        z-index: 2;
        position: absolute;
        top: 10px;
        left: 20px;
        opacity: 0.6;
      }
      .radio {
        cursor: pointer;
        opacity: 1;
        top: 50%;
        transform: translateY(-50%);
        font-size: 24px;
      }
      .toggle-switch {
        transition: 0.4s ease-in-out;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        width: 120px;
        height: 60px;
        border-radius: 1000px;
        .cross {
          background: url("../../assets/cross.svg");
          background-size: cover;
          background-repeat: no-repeat;
          height: 25px;
          position: absolute;
          width: 25px;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
        .checkmark {
          background: url("../../assets/checkmark.svg");
          background-size: cover;
          background-repeat: no-repeat;
          height: 30px;
          position: absolute;
          width: 30px;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
        .toggle {
          z-index: 2;
          transition: 0.4s ease-in-out;
          background: white;
          height: 80%;
          border-radius: 1000px;
          position: absolute;
          aspect-ratio: 1 / 1;
          top: 50%;
          transform: translateY(-50%);

        }
      }
      .mintable-true {
        cursor: pointer;
        background: #396439;
        .toggle {
          inset: 50% 0 0 65px;
        }
      }
      .mintable-false {
        background: #651a1a;
        .toggle {
          inset: 50% 0 0 5px;
        }
      }
    }
  }

  .mintable-container {
    user-select: none;
    cursor: pointer;
    border: 1px solid rgb(44, 47, 54);
  }
  .placeholder {
    color: transparent;
  }
  .error {
    text-align: center;
  }
  .submit-button {
    user-select: none;
    padding: 18px;
    width: 100%;
    text-align: center;
    border-radius: 20px;
    outline: none;
    border: 1px solid transparent;
    text-decoration: none;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    flex-wrap: nowrap;
    -moz-box-align: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    background-color: rgb(165, 139, 82);
    color: white;
    font-size: 20px;
    font-weight: 500;
    &:hover {
      background: #9a814d;
    }
  }
}

.coin-step {
  border-radius: 1000px;
  max-height: 500px;
  min-height: 500px;
  min-width: 500px;
  max-width: 500px;
  animation: turn 0.6s ease-in-out 0.2s forwards;
  div {
    animation: fadeOut 0.7s ease-in-out forwards;
  }
}

.coin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  min-height: 500px;
  max-width: 500px;
  min-width: 500px;
  width: 500px;
  background: #1d1d1e;
  transform: rotateX(75deg) rotateZ(30deg);
  border: #9a814d 2px solid;
  border-radius: 1000px;
  position: relative;
  animation: turnup 0.4s 1.5s forwards ease-in-out;

  .image {
    min-height: 450px;
    margin: 50px;
    min-width: 450px;
    border-radius: 1000px;
    background: #E7EEFA;
    border: #9a814d 2px solid;
    opacity: 0;
    animation: appear 0s 1s forwards;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.stamp {
  background: url('../../assets/stamp.svg');
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  aspect-ratio: 1 / 1;
  width: 400px;
  animation: stamp 1s 0.5s forwards ease-in-out;
  transform: translateY(-2000px) translateZ(250px);
  z-index: 20;
  .image {
    position: absolute;
    height: 180px;
    aspect-ratio: 1 / 1;
    left: 50%;
    top: 3%;
    transform: translateX(-50%);
  }
}

.token {
  //background: url("../../public/assets/zeniq.png");
  //background-size: 20px 20px;
  //background-color: #1d1d1e;
  animation: flip 0.5s forwards;
  .token-info {
    text-align: center;
    background: white;
    min-height: 450px;
    margin: 50px;
    min-width: 450px;
    border-radius: 1000px;
    border: #9a814d 2px solid;
    animation: appear-info 3s forwards;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    color: black;
    transform: scaleX(-1);

    .top {
      //justify-content: center;
      //align-items: center;
      font-family: Oxanium, serif;
      z-index: 5;
      display: flex;
      gap: 10px;
      font-size: 48px;
    }
    .contract {
      max-width: min(300px, 90vw);
      font-size: 20px;
      word-break: break-word;
    }
    .name {
      font-weight: bold;
      font-size: 28px;
      //word-break: break-word;
      max-width: min(250px, 90vw);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.shadow {
  position: absolute;
  height: 800px;
  width: 800px;
  //background: url("../../public/assets/zeniq.png");
  background-size: 70px 70px;
  //background-color: #1d1d1e;
  background: #d7a43b;
  z-index: -1;
  border-radius: 1000px;
  filter: blur(2px);
  mask: radial-gradient(
                  rgba(0, 0, 0, 1) 0%,
                  rgba(0, 0, 0, 0) 60%
  );
  animation: shadow-grow 2s 2s ease-in-out backwards;
}

.help {
  z-index: 10;
  position: fixed;
  top: 20px;
  right: 20px;
  background: #5b5b5b;
  height: 40px;
  width: 40px;
  border-radius: 1000px;
  //@media (min-width: 850px) {
  //  .tooltip {
  //    display: none;
  //  }
  //  &:hover {
  //    .tooltip {
  //      display: block;
  //    }
  //  }
  //}
  .tooltip {
    position: absolute;
    width: 200px;
    color: white;
    background: #5b5b5b;
    bottom: -10px;
    transform: translateY(100%);
    right: -10px;
    padding: 10px;
    border-radius: 15px;
    &:after {
      z-index: -1;
      background: #5b5b5b;
      content: '';
      position: absolute;
      height: 40px;
      width: 40px;
      top: 2px;
      transform: translateY(-3px) rotate(45deg);
      right: 10px;
    }
  }
}


.button-container {
  z-index: 10;
  cursor: pointer;
  margin-left: 20px;
  background: #1b1b1c;
  padding: 15px;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  color: white;
  position: fixed;
  bottom: 20px;
  right: 30px;
  border-radius: 10px;
  border: 1px solid #414141;
  .close {
    position: absolute;
    top: -15px;
    right: -15px;
    background: #1b1b1c;
    border-radius: 1000px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #989898;

  }
  .banner {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    margin-right: 30px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }
    @media (max-width: 500px) {
      flex-direction: column;
      text-align: center;

    }
  }
}
.hide-banner {
  animation: banner 0.7s ease-in-out forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    display: none;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes turn {
  0% {
    transform: rotateX(0deg) rotateZ(0deg);
  }
  100% {
    border: #9a814d 2px solid;
    transform: rotateX(75deg) rotateZ(30deg);
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes appear-info {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes stamp {
  0% {
    transform: translateY(-2000px) translateZ(250px);;
  }
  50% {
    transform: translateY(-150px) translateZ(250px);;
  }
}
@keyframes turnup {
  0% {
    transform: rotateX(75deg) rotateZ(30deg);
  }
  100% {
    transform: rotateX(0deg) rotateZ(0deg);
  }
}
@keyframes shadow-grow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flip {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
}
@keyframes banner {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(1000px);
  }

}