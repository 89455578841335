.content {
    flex-grow: 1;
    width: 100%;
    height: 100%;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}